<h1>{{inspection.inspectionTypeName}}</h1>

<section>
    <h2>{{dealerName}}</h2>
    <p>Started {{funcs.dateFormat(inspection.createdDate)}}</p>
    <ng-container *ngIf="daysSinceCompletion <= 30">
    <p *ngFor="let f of inspectionForms">
        <a href="/observation/{{inspectionID}}/{{f.formID}}">{{f.formName}}</a>
        <span *ngIf="f.maxInstances > 1">
        (1 - {{f.maxInstances}})
        </span>
    </p>
    </ng-container>
</section>

<section>
    <h2>Actions</h2>
    <p><a href="/report/{{inspectionID}}">View Printable Report</a></p>
    <ng-container *ngIf="!inspection.completedDate">
        <p><button (click)="complete()" [disabled]="!requiredData" class="action">Submit Completed Report</button></p>
        <p><button (click)="delete()" class="warning">Delete Report</button></p>
    </ng-container>
    <ng-container *ngIf="!!inspection.completedDate">
        <p>Report completed on  {{funcs.dateFormat(inspection.completedDate)}}</p>
    </ng-container>
    <ng-container *ngIf="!requiredData">
        <p>Missing required data.</p>
        <ul *ngFor="let q of answerErrors">
           <li>{{q.errorText}} <a href="/observation/{{inspectionID}}/{{q.formID}}">{{q.formName}}</a>
                <span *ngIf="q.tabIdx > 0"> (Tab {{q.tabIdx}})</span>
            </li>
        </ul>
    </ng-container>
</section>
