import { Component } from '@angular/core';

import { Question } from '../../data/question';
import { AnswerType } from '../../data/answer-type';
import { QuestionService } from '../../services/question.service';
import { AnswerTypeService } from '../../services/answer-type.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {
  question: Question = <Question>{};
  questions: Question[] = [];
  displayedQuestions = this.questions;
  search = "";
  answerTypes: AnswerType[] = [];

  constructor(
    // private titleService: Title,
    private questionService: QuestionService,
    private answerTypeService: AnswerTypeService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.questionService.getQuestions().subscribe(questions => {
      this.questions = questions;
      this.funcs.hideSpinner();
    });
    this.answerTypeService.getAnswerTypes().subscribe(answerTypes => {
      this.answerTypes = answerTypes;
    });
  }

  addQuestion(): void {
    this.funcs.showSpinner();
    this.questionService.addQuestion(this.question).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }
}
