import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { FormQuestion, FormQuestionFull } from '../data/form-question';

@Injectable({
  providedIn: 'root',
})
export class FormQuestionService {
  private apiEndpoint = `${environment.apiUrl}/api/FormQuestion`;

  constructor(private http: HttpClient) { }

  addFormQuestion(form: FormQuestion): Observable<FormQuestion> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<FormQuestion>(url, form)
      .pipe(
        tap(_ => this.log('Added Form Question')),
        catchError(this.handleError<FormQuestion>('addFormQuestion'))
      );
  }

  updateFormQuestion(FormQuestionID: string, FormQuestion: FormQuestion): Observable<FormQuestion> {
    const url = `${this.apiEndpoint}/${FormQuestionID}`;
    return this.http.put<FormQuestion>(url, FormQuestion)
      .pipe(
        tap(_ => this.log('Update form section')),
        catchError(this.handleError<FormQuestion>('updateFormQuestion'))
      );
  }

  getFormQuestion(FormQuestionID: string): Observable<FormQuestion> {
    const url = `${this.apiEndpoint}/${FormQuestionID}`;
    return this.http.get<FormQuestion>(url)
      .pipe(
        tap(_ => this.log('fetched Form Question')),
        catchError(this.handleError<FormQuestion>('getFormQuestion'))
      );
  }

  getFormQuestions(): Observable<FormQuestion[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<FormQuestion[]>(url)
      .pipe(
        tap(_ => this.log('fetched Form Questions')),
        catchError(this.handleError<FormQuestion[]>('getForms'))
      );
  }

  getFullFormQuestions(FormID: number): Observable<FormQuestionFull[]> {
    const url = `${this.apiEndpoint}/Full/${FormID}`;
    return this.http.get<FormQuestionFull[]>(url)
      .pipe(
        tap(_ => this.log('fetched full Form Questions')),
        catchError(this.handleError<FormQuestionFull[]>('getFullForms'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}