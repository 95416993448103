<h1>Inspection form {{inspectionForm.inspectionFormID}}</h1>

<p><a href="inspection-forms">All Inspection Forms</a></p>

<section>
    <p><label></label>
        <select [(ngModel)]="inspectionForm.inspectionTypeID">
            <option *ngFor="let it of inspectionTypes" value="{{it.inspectionTypeID}}">{{it.inspectionTypeName}}</option>
        </select>
    </p>
    <p><label>Form </label>
        <select [(ngModel)]="inspectionForm.formID">
            <option *ngFor="let f of forms" value="{{f.formID}}">{{f.formName}}</option>
        </select>
    </p>
    <p><label>Max Instances </label> <input type="number" step="1" name="inspection-form-max-instances" [(ngModel)]="inspectionForm.maxInstances" required></p>
    <p><label>Country </label>
        <select [(ngModel)]="inspectionForm.country">
            <option *ngFor="let c of countries" value="{{c}}">{{c}}</option>
        </select>
    </p>
    <p><label><input type="checkbox" [(ngModel)]="inspectionForm.optional" value="1"> Optional</label></p>
    <p><label>Order </label> <input type="number" step="1" name="inspection-form-order" [(ngModel)]="inspectionForm.formOrder" required></p>
    <p><button (click)="updateInspectionForm()">Update Inspection Form</button></p>
</section>