<h1>Questions</h1>

<section>
    <p><label>Question </label> <input type="text" name="question-text" [(ngModel)]="question.questionText"></p>
    <p><label>Answer Type </label>
        <select [(ngModel)]="question.answerTypeID">
            <option value="" selected="selected">Select Answer Type</option>
            <option *ngFor="let at of answerTypes" value="{{at.answerTypeID}}">{{at.answerTypeName}}</option>
        </select>
    </p>
    <p></p>
    <p><label>Min </label> <input [(ngModel)]="question.min" type="number"></p>
    <p><label>Max </label> <input [(ngModel)]="question.max" type="number"></p>
    <p><label>Step </label> <input [(ngModel)]="question.step" type="number"></p>
    <p><label>Formula </label> <input [(ngModel)]="question.formula"></p>
    <p><button (click)="addQuestion()">New Question</button></p>
</section>

<section>
    <!-- <p>
        Filter by Dealer Code: <input id="dealerFilter" [(ngModel)]="search" (keyup)="displayDealers()" autofocus="autofocus"> 
    </p> -->
    <div *ngFor="let q of questions">
        <p><a href="/question/{{q.questionID}}">{{q.questionText}}</a></p>
    </div>
</section>
