import { Component } from '@angular/core';

import { Form } from '../../data/form';
import { FormSection } from '../../data/form-section';
import { FormSectionService } from '../../services/form-section.service';
import { FormService } from '../../services/form.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-form-sections',
  templateUrl: './form-sections.component.html',
  styleUrls: ['./form-sections.component.css']
})
export class FormSectionsComponent {
  formSection: FormSection = <FormSection>{};
  formSections: FormSection[] = [];
  displayedFormSections = this.formSections;
  forms: Form[] = [];
  layouts: string[] = [
    "",
    "tabular",
    "right-align"
  ];

  constructor(
    private formSectionService: FormSectionService,
    private formService: FormService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.formSectionService.getFormSections().subscribe(formSections => {
      this.formSections = formSections;
      this.formService.getForms().subscribe(forms => {
        this.forms = forms;
        this.formSection.formID = this.forms[0].formID;
        this.formSection.formSectionOrder = 0;
        this.funcs.hideSpinner();
      });
    });
  }

  addFormSection(): void {
    this.funcs.showSpinner();
    this.formSectionService.addFormSection(this.formSection).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }
}
