import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Question } from '../../data/question';
import { AnswerType } from '../../data/answer-type';
import { QuestionService } from '../../services/question.service';
import { AnswerTypeService } from '../../services/answer-type.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent {
  question: Question = <Question>{};
  questionID: string = "";
  answerTypes: AnswerType[] = [];

  constructor(
    // private titleService: Title,
    private questionService: QuestionService,
    private answerTypeService: AnswerTypeService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.questionID = this.route.snapshot.paramMap.get('questionID') || "";
    this.funcs.showSpinner();
    this.answerTypeService.getAnswerTypes().subscribe(answerTypes => {
      this.answerTypes = answerTypes;
      this.questionService.getQuestion(this.questionID).subscribe(question => {
        this.question = question;
        this.funcs.hideSpinner();
      });
    });
  }

  updateQuestion(): void {
    this.funcs.showSpinner();
    this.questionService.updateQuestion(this.questionID, this.question).subscribe(rsp => {
      this.funcs.hideSpinner();
    });
  }
}
