<h1>Form Question</h1>

<p><a href="form-questions">All Form Questions</a></p>

<section>
    <!-- <p><label>Form Section </label>
        <select [(ngModel)]="formQuestion.formSectionID">
            <option value="" selected="selected">Select Form Section</option>
            <option *ngFor="let s of sections" value="{{s.formSectionID}}">{{s.title}}</option>
        </select>
    </p>
    <p><label>Question </label>
        <select [(ngModel)]="formQuestion.questionID">
            <option value="" selected="selected">Select Question</option>
            <option *ngFor="let q of questions" value="{{q.questionID}}">{{q.questionText}}</option>
        </select>
    </p> -->
    <!-- <p><label>Form Section ID </label> <input [(ngModel)]="formQuestion.formSectionID" type="number"></p>
    <p><label>Question ID </label> <input [(ngModel)]="formQuestion.questionID" type="number"></p> -->
    <p><label>Form Section</label>: <em>{{section.title}}</em></p>
    <p><label>Question</label>: <em>{{question.questionText}}</em></p>
    <p><label><input type="checkbox" [(ngModel)]="formQuestion.required" value="1"> Required</label></p>

    <p><button (click)="updateQuestion()">Update Form Question</button></p>
</section>
