import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormSection } from '../../data/form-section';
import { Form } from '../../data/form';
import { FormSectionService } from '../../services/form-section.service';
import { FormService } from '../../services/form.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-form',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.css']
})
export class FormSectionComponent {
  formSection: FormSection = <FormSection>{};
  formSectionID: string = "";
  forms: Form[] = [];
  layouts: string[] = [
    "",
    "tabular",
    "right-align"
  ];

  constructor(
    private formSectionService: FormSectionService,
    private formService: FormService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.formSectionID = this.route.snapshot.paramMap.get('formSectionID') || "";
    this.funcs.showSpinner();
    this.formSectionService.getFormSection(this.formSectionID).subscribe(formSection => {
      this.formSection = formSection;
      this.formService.getForms().subscribe(forms => {
        this.forms = forms;
        this.funcs.hideSpinner();
      });
      this.funcs.hideSpinner();
    });
  }

  updateFormSection(): void {
    this.funcs.showSpinner();
    this.formSectionService.updateFormSection(this.formSectionID, this.formSection).subscribe(rsp => {
      this.funcs.hideSpinner();
    });
  }
}
