import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Question } from '../data/question';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private apiEndpoint = `${environment.apiUrl}/api/Question`;

  constructor(private http: HttpClient) { }

  addQuestion(question: Question): Observable<Question> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<Question>(url, question)
      .pipe(
        tap(_ => this.log('Added new question')),
        catchError(this.handleError<Question>('addQuestion'))
      );
  }

  updateQuestion(questionID: string, question: Question): Observable<Question> {
    const url = `${this.apiEndpoint}/${questionID}`;
    return this.http.put<Question>(url, question)
      .pipe(
        tap(_ => this.log('Update question')),
        catchError(this.handleError<Question>('updateQuestion'))
      );
  }

  getQuestion(questionId: string): Observable<Question> {
    const url = `${this.apiEndpoint}/${questionId}`;
    return this.http.get<Question>(url)
      .pipe(
        tap(_ => this.log('fetched question')),
        catchError(this.handleError<Question>('getQuestion'))
      );
  }

  getQuestions(): Observable<Question[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<Question[]>(url)
      .pipe(
        tap(_ => this.log('fetched questions')),
        catchError(this.handleError<Question[]>('getQuestions'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}