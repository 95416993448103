import { Component } from '@angular/core';
import { User, UserCurrent } from '../../data/user';
import { Router } from '@angular/router';
import { FuncsService } from '../../services/funcs.service';
// import {  } from '../../data/user';
import { InspectionType } from '../../data/inspection-type';
import { InspectionTypeService } from '../../services/inspection-type.service';
import { Form } from '../../data/form';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent {
  user: User = UserCurrent;
  inspectionTypes: InspectionType[] = [];
  forms: Form[] = [];

  constructor(
    private inspectionTypeService: InspectionTypeService,
    private formService: FormService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.funcs.currentUser(UserCurrent);

    this.funcs.showSpinner();
    this.inspectionTypeService.getInspectionTypes().subscribe(inspectionTypes => {
      this.inspectionTypes = inspectionTypes;
      this.formService.getForms().subscribe(forms => {
        this.forms = forms;
        this.funcs.hideSpinner();
      });
    });
  }
}
