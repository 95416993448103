import { Component } from '@angular/core';

import { InspectionType } from '../../data/inspection-type';
import { InspectionTypeService } from '../../services/inspection-type.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-forms',
  templateUrl: './inspection-types.component.html',
  styleUrls: ['./inspection-types.component.css']
})
export class InspectionTypesComponent {
  inspectionType: InspectionType = <InspectionType>{};
  inspectionTypes: InspectionType[] = [];

  constructor(
    private inspectionTypeService: InspectionTypeService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.inspectionTypeService.getInspectionTypes().subscribe(inspectionTypes => {
      this.inspectionTypes = inspectionTypes;
      this.funcs.hideSpinner();
    });
  }

  addForm(): void {
    this.funcs.showSpinner();
    this.inspectionTypeService.addInspectionType(this.inspectionType).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }
}
