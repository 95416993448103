import { Component } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';

import { InspectionFull } from '../../data/inspection';
import { User } from '../../data/user';

import { InspectionService } from '../../services/inspection.service';
import { UserService } from '../../services/user.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  inProgress: InspectionFull[] = [];
  inProgressCount: number = 0;
  completed: InspectionFull[] = [];
  completedCount: number = 0;
  currentUser: User = <User>{};
  selectedUser: string = "";

  constructor(
    private titleService: Title,
    private inspectionService: InspectionService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Consultant Home - Helm Service Consulting");
    this.currentUser = this.userService.getCurrentUser();
    this.funcs.checkConsultant();

    if (this.funcs.isAdmin()) {
      // if the user is an admin, get the user information from the url
      this.selectedUser = this.route.snapshot.paramMap.get('UserID') || "";
      // if no user id, then redirect to admin page
      if (!this.selectedUser) {
        this.router.navigateByUrl('/admin');
      } 
    } else {
      // if the user is a consultant, just get their info
      this.selectedUser = this.currentUser.userID;
    }
    this.funcs.showSpinner();
    this.getInspections(this.selectedUser);
  }

  getInspections(userId: string): void {
    this.inspectionService.getInspectionsFull(0, userId, '').subscribe(result => {
      result.forEach(i => {
        if (i.completedDate) {
          this.completed.push(i);
        } else {
          this.inProgress.push(i);
        }
      });
      this.inProgressCount = this.inProgress.length;
      this.completedCount = this.completed.length;
      this.funcs.hideSpinner();
    },
    error => {
      console.log(error);
    });
  }
}
