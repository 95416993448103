import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SelectOption, SelectOptions } from '../data/select-option';

@Injectable({
  providedIn: 'root',
})
export class SelectOptionService {
  private apiEndpoint = `${environment.apiUrl}/api/SelectOption`;

  constructor(private http: HttpClient) { }

  addSelectOption(inspection: SelectOption): Observable<SelectOption> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<SelectOption>(url, inspection)
      .pipe(
        tap(_ => this.log('Added SelectOption')),
        catchError(this.handleError<SelectOption>('addSelectOption'))
      );
  }

  getSelectOption(SelectOptionID: string): Observable<SelectOption> {
    const url = `${this.apiEndpoint}/${SelectOptionID}`;
    return this.http.get<SelectOption>(url)
      .pipe(
        tap(_ => this.log('fetched select option')),
        catchError(this.handleError<SelectOption>('getSelectOption'))
      );
  }

  getSelectOptions(): Observable<SelectOption[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<SelectOption[]>(url)
      .pipe(
        tap(_ => this.log('fetched select options')),
        catchError(this.handleError<SelectOption[]>('getSelectOptions'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}