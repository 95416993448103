import { Component } from '@angular/core';

import { InspectionForm } from '../../data/inspection-form';
import { InspectionFormService } from '../../services/inspection-form.service';
import { InspectionType } from '../../data/inspection-type';
import { InspectionTypeService } from '../../services/inspection-type.service';
import { Form } from '../../data/form';
import { FormService } from '../../services/form.service';
// import { FormService } from '../../services/form.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-inspection-forms',
  templateUrl: './inspection-forms.component.html',
  styleUrls: ['./inspection-forms.component.css']
})
export class InspectionFormsComponent {
  inspectionForm: InspectionForm = <InspectionForm>{};
  inspectionForms: InspectionForm[] = [];
  inspectionTypes: InspectionType[] = [];
  forms: Form[] = [];
  countries: string[] = [
    "",
    "US",
    "Canada"
  ];

  constructor(
    private inspectionFormService: InspectionFormService,
    private inspectionTypeService: InspectionTypeService,
    private formService: FormService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.inspectionFormService.getInspectionForms().subscribe(inspectionForms => {
      this.inspectionForms = inspectionForms;
      this.inspectionTypeService.getInspectionTypes().subscribe(inspectionTypes => {
        this.inspectionTypes = inspectionTypes;

        this.formService.getForms().subscribe(forms => {
          this.forms = forms;
          this.funcs.hideSpinner();
        });
      });
    });
  }

  addInspectionForm(): void {
    this.funcs.showSpinner();
    this.inspectionFormService.addInspectionForm(this.inspectionForm).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }

  getInspectionTypeCode(inspectionTypeID: number): string {
    var inspectionType = this.inspectionTypes.find(it => inspectionTypeID == it.inspectionTypeID);
    if (inspectionType) {
      return inspectionType.inspectionTypeCode;
    }
    return "";
  }

  getInspectionFormName(inspectionFormID: number): string {
    var inspectionForm = this.forms.find(f => inspectionFormID == f.formID);
    if (inspectionForm) {
      return inspectionForm.formName;
    }
    return inspectionFormID.toString(10);
  }
}
