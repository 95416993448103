<h1>Forms</h1>

<section>
    <p><label>Form Code </label> <input type="text" name="form-code" [(ngModel)]="form.formCode" required></p>
    <p><label>Form Name </label> <input type="text" name="form-name" [(ngModel)]="form.formName" required></p>
    <p><label>Display Type </label>
        <select [(ngModel)]="form.formDisplayed">
            <option *ngFor="let at of displayTypes" value="{{at}}">{{at}}</option>
        </select>
    </p>
    <p><button (click)="addForm()">New Form</button></p>
</section>

<section>
    <div *ngFor="let f of forms">
        <p><a href="/form/{{f.formID}}">{{f.formName}}</a></p>
    </div>
</section>
