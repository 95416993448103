import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { InspectionType, InspectionTypes } from '../data/inspection-type';

@Injectable({
  providedIn: 'root',
})
export class InspectionTypeService {
  private apiEndpoint = `${environment.apiUrl}/api/InspectionType`;

  constructor(private http: HttpClient) { }

  addInspectionType(inspection: InspectionType): Observable<InspectionType> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<InspectionType>(url, inspection)
      .pipe(
        tap(_ => this.log('Added InspectionType')),
        catchError(this.handleError<InspectionType>('addInspectionType'))
      );
  }

  updateInspectionType(inspectionTypeID: string, inspectionType: InspectionType): Observable<InspectionType> {
    const url = `${this.apiEndpoint}/${inspectionTypeID}`;
    return this.http.put<InspectionType>(url, inspectionType)
      .pipe(
        tap(_ => this.log('Update inspection type')),
        catchError(this.handleError<InspectionType>('updateInspectionType'))
      );
  }

  getInspectionType(InspectionTypeID: string): Observable<InspectionType> {
    const url = `${this.apiEndpoint}/${InspectionTypeID}`;
    return this.http.get<InspectionType>(url)
      .pipe(
        tap(_ => this.log('fetched Inspection Type')),
        catchError(this.handleError<InspectionType>('getInspectionType'))
      );
  }

  getInspectionTypes(): Observable<InspectionType[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<InspectionType[]>(url)
      .pipe(
        tap(_ => this.log('fetched Inspection Types')),
        catchError(this.handleError<InspectionType[]>('getInspectionTypes'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}