<h1>{{formSection.title}}</h1>

<p><a href="form-sections">All Form Sections</a></p>

<!-- <section>
    <p><label>Form Code </label> <input type="text" name="form-code" [(ngModel)]="form.formCode"></p>
    <p><label>Form Name </label> <input type="text" name="form-name" [(ngModel)]="form.formName"></p>
    <p><label>Display Type </label>
        <select [(ngModel)]="form.formDisplayed">
            <option *ngFor="let at of displayTypes" value="{{at}}">{{at}}</option>
        </select>
    </p>
    <p><button (click)="updateForm()">Update Form</button></p>
</section> -->

<section>
    <p><label>Form </label>
        <select [(ngModel)]="formSection.formID">
            <option *ngFor="let f of forms" value="{{f.formID}}">{{f.formName}}</option>
        </select>
    </p>
    <p><label>Title </label> <input type="text" name="form-section-title" [(ngModel)]="formSection.title"></p>
    <p><label>Description </label> <textarea type="text" name="form-section-desc" [(ngModel)]="formSection.desc"></textarea></p>
    <p><label>Report Layout </label>
        <select [(ngModel)]="formSection.reportLayout">
            <option *ngFor="let lo of layouts" value="{{lo}}">{{lo}}</option>
        </select>
    </p>
    <p><label>Order </label> <input type="number" step="1" name="form-section-order" [(ngModel)]="formSection.formSectionOrder" required></p>
    <p><button (click)="updateFormSection()">Update Form Section</button></p>
</section>