<button (click)="print()" id="print">Print</button>
<ul id="reportHeader">
    <li><a href="/inspection/{{inspectionID}}">{{dataEntryText}}</a></li>
    <li>{{lang.languageText}}</li>
    <li *ngFor="let f of forms"><a href="{{url}}#form-{{f.formCode}}">{{f.formName}}</a></li>
</ul>

<div *ngIf="!hasData">No Data</div>

<div *ngFor="let f of forms" class="reportForm">
    <div *ngIf="f.formDisplayed == 'header'">
        <p id="serviceFlowLogo"><img src="/assets/mel.png"></p>
        <h2 id="form-{{f.formCode}}"><a href="/observation/{{inspectionID}}/{{f.formID}}">{{f.formName}}</a></h2>
        <h4>{{funcs.dateFormat(completedDate)}}</h4>
        <h4>{{funcs.capitalize(dealerName)}} {{dealerCode}}</h4>
    </div>
    <div *ngIf="f.formDisplayed == 'cover'" id="action-plan-cover">
        <p id="actionPlanLogo"><img src="/assets/mel.png"></p>
        <h2 id="form-{{f.formCode}}">{{f.formName}}</h2>
        <h5><a href="/observation/{{inspectionID}}/{{f.formID}}">{{f.formName}} Data Entry</a></h5>
        <h3>{{funcs.capitalize(dealerName)}}</h3>
        <h3>{{dealerCode}}</h3>
        <table>
            <tr><td>Consultant Name: </td><th>{{consultant.firstName}} {{consultant.lastName}}</th></tr>
            <tr><td>Date: </td><th>{{funcs.dateFormat(completedDate)}}</th></tr>
        </table>
    </div>

    <div *ngFor="let s of answerForms[f.formCode].answerSections">
        <section [className]="s.formSectionLayout == 'tabular' || s.formSectionReportLayout == 'shared-tab' ? '' : 'avoid-break'">
            <h3>{{s.formSectionTitle}} <span *ngIf="answerForms[f.formCode].maxInstances > 1 && s.formSectionTitle && s.formSectionLayout != 'tabular'">{{s.formTabIndex}}</span></h3>
            <p *ngIf="s.formSectionDescription" class="sectionDescription">{{s.formSectionDescription}}</p>
            <table *ngIf="s.formSectionLayout == 'tabular'">
                <thead><tr><th></th><td *ngFor="let fs of filterSharedTab(s.formSectionId)[0].answerText; index as ii">{{ii + 1}}</td></tr></thead>
                <tr *ngFor="let a of s.formSectionAnswers">
                    <th>{{a.questionText}}</th>
                    <ng-container *ngFor="let at of filterTab(a.formQuestionID)">
                        <td>
                            <span [ngClass]="formatClassYesNo(s.formSectionLayout, a.answerText, a.answerTypeName)">
                                {{expandYesNo(at, a.answerTypeName)}}
                            </span>
                        </td>
                    </ng-container>
                </tr>
            </table>
            <div *ngIf="s.formSectionReportLayout == 'shared-tab'">
                <div *ngFor="let fs of filterSharedTab(s.formSectionId)[0].answerText; index as ii" class="avoid-break">
                    <ng-container *ngIf="ii == 0">
                        <ng-container *ngFor="let a of s.formSectionAnswers">
                            <div *ngIf="a.answerTypeName == 'Text Area' && a.answerText.trimRight() != ''">
                                <p>{{a.questionText}}</p>
                                <pre>{{a.answerText}}</pre>
                            </div>
                            <div *ngIf="a.answerTypeName != 'Text Area'">
                                <p>{{a.questionText}}{{addColon(a.questionText)}} 
                                    <span [ngClass]="formatClassYesNo(s.formSectionLayout, a.answerText, a.answerTypeName)">
                                        {{expandYesNo(a.answerText, a.answerTypeName)}}
                                    </span>
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="ii > 0">
                        <h3>{{s.formSectionTitle}} {{ii + 1}}</h3>
                        <ng-container *ngFor="let a of filterSharedTab(s.formSectionId)">
                            <div *ngIf="a.answerTypeName == 'Text Area' && a.answerText[ii].trimRight() != ''">
                                <p>{{a.questionText}}</p>
                                <pre>{{a.answerText[ii]}}</pre>
                            </div>
                            <div *ngIf="a.answerTypeName != 'Text Area'">
                                <p>{{a.questionText}}{{addColon(a.questionText)}} 
                                    <span [ngClass]="formatClassYesNo(s.formSectionLayout, a.answerText[ii], a.answerTypeName)">
                                        {{expandYesNo(a.answerText[ii], a.answerTypeName)}}
                                    </span>
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="s.formSectionLayout != 'tabular' && s.formSectionReportLayout != 'shared-tab'">
                <div *ngFor="let a of s.formSectionAnswers">
                    <div *ngIf="a.answerTypeName == 'Text Area' && a.answerText.trimRight() != ''">
                        <p>{{a.questionText}}</p>
                        <pre>{{a.answerText}}</pre>
                    </div>
                    <div *ngIf="a.answerTypeName != 'Text Area'">
                        <p>{{a.questionText}}{{addColon(a.questionText)}} 
                            <span [ngClass]="formatClassYesNo(s.formSectionLayout, a.answerText, a.answerTypeName)">
                                {{expandYesNo(a.answerText, a.answerTypeName)}}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div *ngIf="f.formCode == 'service-flow'" id="serviceReport">
        <p id="serviceFlowLogo"><img src="/assets/mel.png"></p>
        <h2 id="form-service-flow-report">Service Reports</h2>
        <h4>{{funcs.dateFormat(completedDate)}}</h4>
        <h4>{{funcs.capitalize(dealerName)}} {{dealerCode}}</h4>

        <section>
            <h3>Service Flow Report</h3>
            <table id="serviceFlowReport">
                <thead><tr><td colspan="3">Process Breakdown</td><td>Minutes</td></tr></thead>
                <tbody>
                    <tr>
                        <td>Time Customer Entered</td>
                        <th>➔</th>
                        <td>Customer RO Created</td>
                        <td [style.background-image]="getPercentStyle(0)">{{aveCompletionTime[0] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time Customer RO Created</td>
                        <th>➔</th>
                        <td>Time Vehicle Into Bay</td>
                        <td [style.background-image]="getPercentStyle(1)">{{aveCompletionTime[1] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time Vehicle Into Bay</td>
                        <th>➔</th>
                        <td>Time MPI Completed by Tech</td>
                        <td [style.background-image]="getPercentStyle(2)">{{aveCompletionTime[2] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time MPI Completed by Tech</td>
                        <th>➔</th>
                        <td>Time MPI Reviewed with Customer</td>
                        <td [style.background-image]="getPercentStyle(3)">{{aveCompletionTime[3] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time MPI Reviewed with Customer</td>
                        <th>➔</th>
                        <td>Time Vehicle Completed in Bay</td>
                        <td [style.background-image]="getPercentStyle(4)">{{aveCompletionTime[4] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time Vehicle Completed in Bay</td>
                        <th>➔</th>
                        <td>Time Customer Cashed Out</td>
                        <td [style.background-image]="getPercentStyle(99)">{{aveCompletionBayOut | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time Customer Cashed Out</td>
                        <th>➔</th>
                        <td>Time Customer Leaves Express</td>
                        <td [style.background-image]="getPercentStyle(8)">{{aveCompletionTime[8] | number: '1.0-0'}}</td>
                    </tr>
                    <tr><td>&nbsp;</td><th></th><td></td><td></td></tr>
                    <tr><td><strong>Detailed Car Wash Breakdown</strong></td><th></th><td></td><td></td></tr>
                    <tr>
                        <td>Time Vehicle Completed in Bay</td>
                        <th>➔</th>
                        <td>Time Car Wash Started</td>
                        <td [style.background-image]="getPercentStyle(5)">{{aveCompletionTime[5] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time Car Wash Started</td>
                        <th>➔</th>
                        <td>Time Car Wash Ended</td>
                        <td [style.background-image]="getPercentStyle(6)">{{aveCompletionTime[6] | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <td>Time Car Wash Ended</td>
                        <th>➔</th>
                        <td>Time Customer Cashed Out</td>
                        <td [style.background-image]="getPercentStyle(7)">{{aveCompletionTime[7] | number: '1.0-0'}}</td>
                    </tr>
                </tbody>
            </table>
            <h3>Dealer Average Process Time Breakdown</h3>
            <div id="timeBreakdown"
                ><span [style.width]="aveCompletionTime[0] * 100 / aveCompletionTime[9] + '%'">{{aveCompletionTime[0] | number: '1.0-0'}}</span
                ><span [style.width]="aveCompletionTime[1] * 100 / aveCompletionTime[9] + '%'">{{aveCompletionTime[1] | number: '1.0-0'}}</span
                ><span [style.width]="aveCompletionTime[2] * 100 / aveCompletionTime[9] + '%'">{{aveCompletionTime[2] | number: '1.0-0'}}</span
                ><span [style.width]="aveCompletionTime[3] * 100 / aveCompletionTime[9] + '%'">{{aveCompletionTime[3] | number: '1.0-0'}}</span
                ><span [style.width]="aveCompletionTime[4] * 100 / aveCompletionTime[9] + '%'">{{aveCompletionTime[4] | number: '1.0-0'}}</span
                ><span [style.width]="aveCompletionBayOut * 100 / aveCompletionTime[9] + '%'">{{aveCompletionBayOut | number: '1.0-0'}}</span
                ><span [style.width]="aveCompletionTime[8] * 100 / aveCompletionTime[9] + '%'">{{aveCompletionTime[8] | number: '1.0-0'}}</span
            ></div>
        </section>
        <section>
            <h3>Service Milestone Analysis &amp; Comments</h3>
            <table id="stepGoals">
                <caption>Service Steps vs. Goal Times</caption>
                <thead><tr><td>Step</td><td>Average Completion Time</td><td>Goal</td><td>Difference</td></tr></thead>
                <tbody>
                    <tr>
                        <th>Time Customer Entered ➔<br>Time Customer RO Created</th>
                        <td>{{aveCompletionTime[0] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[0]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[0])"></div>{{diffCompletionTime[0] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time Customer RO Created ➔<br>Time Vehicle Into Bay</th>
                        <td>{{aveCompletionTime[1] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[1]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[1])"></div>{{diffCompletionTime[1] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time Vehicle Into Bay ➔<br>Time MPI Completed by Tech</th>
                        <td>{{aveCompletionTime[2] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[2]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[2])"></div>{{diffCompletionTime[2] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time MPI Completed by Tech ➔<br>Time MPI Reviewed with Customer</th>
                        <td>{{aveCompletionTime[3] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[3]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[3])"></div>{{diffCompletionTime[3] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time MPI Reviewed with Customer ➔<br>Time Vehicle Completed in Bay</th>
                        <td>{{aveCompletionTime[4] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[4]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[4])"></div>{{diffCompletionTime[4] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time Vehicle Completed in Bay ➔<br>Time Car Wash Started</th>
                        <td>{{aveCompletionTime[5] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[5]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[5])"></div>{{diffCompletionTime[5] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time Car Wash Started ➔<br>Time Car Wash Ended</th>
                        <td>{{aveCompletionTime[6] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[6]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[6])"></div>{{diffCompletionTime[6] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time Car Wash Ended ➔<br>Time Customer Cashed Out</th>
                        <td>{{aveCompletionTime[7] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[7]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[7])"></div>{{diffCompletionTime[7] | number: '1.0-0'}}%</td>
                    </tr>
                    <tr>
                        <th>Time Customer Cashed Out ➔<br>Time Customer Leaves Express</th>
                        <td>{{aveCompletionTime[8] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[8]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[8])"></div>{{diffCompletionTime[8] | number: '1.0-0'}}%</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total</th>
                        <td>{{aveCompletionTime[9] | number: '1.0-0'}}</td>
                        <td>{{stepGoals[9]}}</td>
                        <td><div [ngClass]="getDiffClass(diffCompletionTime[9])"></div>{{diffCompletionTime[9] | number: '1.0-0'}}%</td>
                    </tr>
                </tfoot>
            </table>
        </section>
    </div>
    <div *ngIf="f.formCode == 'additional-notes'">
        <section>
            <table id="signatures">
                <caption>Signatures</caption>
                <tr><th>&nbsp;</th><td>&nbsp;</td><th>&nbsp;</th></tr>
                <tr><td>General Manager/Operations</td><td>&nbsp;</td><td>MEL Consultant</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><th>&nbsp;</th><td>&nbsp;</td><th>&nbsp;</th></tr>
                <tr><td>Service Manager</td><td>&nbsp;</td><td>Parts Manager</td></tr>
            </table>
        </section>
    </div>
</div>
