import { Component } from '@angular/core';

import { InspectionFull } from '../../data/inspection';
import { InspectionService } from '../../services/inspection.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent {
  inspections: InspectionFull[] = [];
  search = "";

  constructor(
    // private titleService: Title,
    private inspectionService: InspectionService,
    public funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.funcs.showSpinner();
    this.inspectionService.getInspectionsFull(0, "", "").subscribe(i => {
      this.inspections = i;
      // this.titleService.setTitle("Dealers - Helm Service Consulting");
      // dealers.forEach(d => {
      //   d.dealerName = this.funcs.capitalize(d.dealerName);
      // });
      // this.dealers = dealers;
      // // Angular has a bug where the model is not updated on browser back button.
      // // This ugly hack should fix it.
      // var searchBox = <HTMLInputElement>document.querySelector("#dealerFilter") || <HTMLInputElement>{};
      // this.search = searchBox.value;
      // this.displayDealers();
      this.funcs.hideSpinner();
    });
  }
  // displayDealers() {
  //   this.displayedDealers = this.filterDealers();
  // }

  // private filterDealers = () => {
  //   return this.dealers.filter(this.isDealer);
  // };

  // private isDealer = (d: Dealer) => {
  //   var ciSearch = this.search.toLowerCase();
  //   var ciName = d.dealerName.toLowerCase();
  //   var filtered = (d.dealerCode.indexOf(ciSearch) > -1 || ciName.indexOf(ciSearch) > -1) || !ciSearch;
  //   return filtered
  // };
}
