<h1>{{form.formName}}</h1>

<p><a href="forms">All Forms</a></p>

<section>
    <p><label>Form Code </label> <input type="text" name="form-code" [(ngModel)]="form.formCode"></p>
    <p><label>Form Name </label> <input type="text" name="form-name" [(ngModel)]="form.formName"></p>
    <p><label>Display Type </label>
        <select [(ngModel)]="form.formDisplayed">
            <option *ngFor="let at of displayTypes" value="{{at}}">{{at}}</option>
        </select>
    </p>
    <p><button (click)="updateForm()">Update Form</button></p>
</section>
