import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { Question } from '../../data/question';
import { FormQuestion } from '../../data/form-question';
import { FormSection } from '../../data/form-section';

import { QuestionService } from '../../services/question.service';
import { FormQuestionService } from '../../services/form-question.service';
import { FormSectionService } from '../../services/form-section.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-form-question',
  templateUrl: './form-question.component.html',
  styleUrls: ['./form-question.component.css']
})
export class FormQuestionComponent {
  formQuestion: FormQuestion = <FormQuestion>{};
  formQuestionID: string = "";
  question: Question = <Question>{};
  section: FormSection = <FormSection>{};

  constructor(
    // private titleService: Title,
    private formQuestionService: FormQuestionService,
    private questionService: QuestionService,
    private formSectionService: FormSectionService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.formQuestionID = this.route.snapshot.paramMap.get('formQuestionID') || "";
    this.funcs.showSpinner();

    let formQuestionRequest = this.formQuestionService.getFormQuestion(this.formQuestionID);
    let questionRequest = this.questionService.getQuestions();
    let formSectionRequest = this.formSectionService.getFormSections();

    forkJoin([formQuestionRequest, questionRequest, formSectionRequest]).subscribe(responses => {
      this.formQuestion = responses[0];
      this.question = responses[1].find(q => q.questionID == this.formQuestion.questionID) || <Question>{};
      this.section = responses[2].find(q => q.formSectionID == this.formQuestion.formSectionID) || <FormSection>{};

      this.funcs.hideSpinner();
    });
  }

  updateQuestion(): void {
    this.funcs.showSpinner();
    this.formQuestionService.updateFormQuestion(this.formQuestionID, this.formQuestion).subscribe(rsp => {
      this.funcs.hideSpinner();
    });
  }
}
