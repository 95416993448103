<h1>Admin</h1>

<section>
    <h4><a href="/home/{{user.userID}}">My Inspections</a></h4>
    <h4><a href="/inspections">All Inspections</a></h4>
    <h4><a href="/dealers">Dealers</a></h4>
    <h4><a href="/users">Users</a></h4>
</section>
<section>
    <h2>Form Maintenance</h2>
    <h4><a href="/inspection-types">Inspection Types</a></h4>
    <ul>
        <li *ngFor="let it of inspectionTypes">
            <a href="/inspection-type/{{it.inspectionTypeID}}">{{it.inspectionTypeName}}</a>
        </li>
    </ul>
    <h4><a href="/questions">Questions</a></h4>
    <p>Create and maintain question text, answer type, and optional validation parameters.</p>
    <h4><a href="/forms">Forms</a></h4>
    <ul>
        <li *ngFor="let f of forms">
            <a href="/form/{{f.formID}}">{{f.formName}}</a>
        </li>
    </ul>
    <h4><a href="/form-sections">Form Sections</a></h4>
    <h4><a href="/form-questions">Form Questions</a></h4>
    <p>Associate questions to form sections. Establish display order and if it is a required answer.</p>
    <h4><a href="/inspection-forms">Inspection Forms</a></h4>
    <p>Associate forms to inspection types. Establish display order, maximum number of tabs, and optional country.</p>
</section>
