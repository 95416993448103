import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { InspectionForm, InspectionForms } from '../data/inspection-form';

@Injectable({
  providedIn: 'root',
})
export class InspectionFormService {
  private apiEndpoint = `${environment.apiUrl}/api/InspectionForm`;

  constructor(private http: HttpClient) { }

  addInspectionForm(inspection: InspectionForm): Observable<InspectionForm> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<InspectionForm>(url, inspection)
      .pipe(
        tap(_ => this.log('Added Inspection Form')),
        catchError(this.handleError<InspectionForm>('addInspectionForm'))
      );
  }

  updateInspectionForm(inspectionFormID: string, inspectionForm: InspectionForm): Observable<InspectionForm> {
    const url = `${this.apiEndpoint}/${inspectionFormID}`;
    return this.http.put<InspectionForm>(url, inspectionForm)
      .pipe(
        tap(_ => this.log('Update inspection form')),
        catchError(this.handleError<InspectionForm>('updateInspectionForm'))
      );
  }

  getInspectionForm(InspectionFormID: string): Observable<InspectionForm> {
    const url = `${this.apiEndpoint}/${InspectionFormID}`;
    return this.http.get<InspectionForm>(url)
      .pipe(
        tap(_ => this.log('fetched Inspection Form')),
        catchError(this.handleError<InspectionForm>('getInspectionForm'))
      );
  }

  getInspectionForms(): Observable<InspectionForm[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<InspectionForm[]>(url)
      .pipe(
        tap(_ => this.log('fetched Inspection Forms')),
        catchError(this.handleError<InspectionForm[]>('getInspectionForms'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}