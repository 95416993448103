<h1>Inspection Forms</h1>

<section>
    <p><label>Inspection Type </label>
        <select [(ngModel)]="inspectionForm.inspectionTypeID">
            <option *ngFor="let it of inspectionTypes" value="{{it.inspectionTypeID}}">{{it.inspectionTypeName}}</option>
        </select>
    </p>
    <p><label>Form </label>
        <select [(ngModel)]="inspectionForm.formID">
            <option *ngFor="let f of forms" value="{{f.formID}}">{{f.formName}}</option>
        </select>
    </p>
    <p><label>Max Instances </label> <input type="number" step="1" name="inspection-form-max-instances" [(ngModel)]="inspectionForm.maxInstances" required></p>
    <p><label>Country </label>
        <select [(ngModel)]="inspectionForm.country">
            <option *ngFor="let c of countries" value="{{c}}">{{c}}</option>
        </select>
    </p>
    <p><label><input type="checkbox" [(ngModel)]="inspectionForm.optional" value="1"> Optional</label></p>
    <p><label>Order </label> <input type="number" step="1" name="inspection-form-order" [(ngModel)]="inspectionForm.formOrder" required></p>
    <p><button (click)="addInspectionForm()">New Inspection Form</button></p>
</section>

<section>
    <div *ngFor="let f of inspectionForms">
        <p>
            <a href="/inspection-form/{{f.inspectionFormID}}">{{getInspectionTypeCode(f.inspectionTypeID)}} / {{getInspectionFormName(f.formID)}}</a>
            <span *ngIf="!!f.country"> ({{f.country}} only)</span>
        </p>
    </div>
</section>
