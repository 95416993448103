import { Component } from '@angular/core';
import { User } from '../../data/user';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {
  user: User = <User>{};
  userID: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  role: number = 13;
  password: string = "";
  passwordConfirm: string = "";
  isAdmin: boolean = false;
  message = "";

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.userID = this.route.snapshot.paramMap.get('userID') || "";
    this.isAdmin = this.funcs.isAdmin();
    this.funcs.checkAdminOrSelf(this.userID);

    this.userService.getUser(this.userID).subscribe(u => {
      this.user = u;
      this.firstName = u.firstName;
      this.lastName = u.lastName;
      this.email = u.userID;
      this.role = u.roleID;
    });
  }

  updateUser(): void {
    this.userService.updateUser(this.firstName, this.lastName, this.email, this.role).subscribe(rsp => {
      location.reload();
    });
  }

  terminateUser(): void {
    this.userService.terminateUser(this.email).subscribe(rsp => {
      location.reload();
    });
  }

  changePassword(): void {
    this.message = "";
    if (this.password !== this.passwordConfirm) {
      this.message = "Passwords don't match.";
      return;
    }
    this.userService.updatePassword(this.email, this.password).subscribe(rsp => {
      location.reload();
    });
  }
}
