import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { FormSection } from '../data/form-section';

@Injectable({
  providedIn: 'root',
})
export class FormSectionService {
  private apiEndpoint = `${environment.apiUrl}/api/FormSection`;

  constructor(private http: HttpClient) { }

  addFormSection(formSection: FormSection): Observable<FormSection> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<FormSection>(url, formSection)
      .pipe(
        tap(_ => this.log('Added Form Section')),
        catchError(this.handleError<FormSection>('addFormSection'))
      );
  }

  updateFormSection(formSectionID: string, formSection: FormSection): Observable<FormSection> {
    const url = `${this.apiEndpoint}/${formSectionID}`;
    return this.http.put<FormSection>(url, formSection)
      .pipe(
        tap(_ => this.log('Update form section')),
        catchError(this.handleError<FormSection>('updateFormSection'))
      );
  }

  getFormSection(FormID: string): Observable<FormSection> {
    const url = `${this.apiEndpoint}/${FormID}`;
    return this.http.get<FormSection>(url)
      .pipe(
        tap(_ => this.log('fetched Form Section')),
        catchError(this.handleError<FormSection>('getFormSection'))
      );
  }

  getFormSections(): Observable<FormSection[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<FormSection[]>(url)
      .pipe(
        tap(_ => this.log('fetched Form Sections')),
        catchError(this.handleError<FormSection[]>('getFormSections'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}