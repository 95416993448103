import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AnswerExport } from '../../data/answer';
import { FuncsService } from '../../services/funcs.service';
import { AnswerService } from '../../services/answer.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent {
  data: AnswerExport[] = [];
  inspectionID: number = 0;
  displayData: string = "";

  constructor(
    // private titleService: Title,
    private answerService: AnswerService,
    public funcs: FuncsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.funcs.showSpinner();
    const inspectionIDstring: string = this.route.snapshot.paramMap.get('inspectionID') || "";
    this.inspectionID = parseInt(inspectionIDstring, 10);

    this.answerService.getAnswersExport(this.inspectionID).subscribe(i => {
      this.data = i;
      this.displayData = JSON.stringify(this.data, null, "    ");
      this.funcs.hideSpinner();
    });
  }
}
