import { Component } from '@angular/core';
import { FuncsService } from './services/funcs.service';
import { Router, NavigationEnd } from '@angular/router';
import { User } from './data/user';
import { Language } from './data/lang';
import { UserService } from './services/user.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Service Consulting';
  navClass = "";
  currentUser: User = <User>{};
  lang: Language = <Language>{};
  public hideLogout = false;
  prod: boolean = environment.production;

  constructor(
    private funcs: FuncsService,
    public userService: UserService,
    private router: Router
  ) {
    router.events.subscribe(val => {
      // hide logout link on login page
      if (val instanceof NavigationEnd) {
        this.hideLogout = window.location.pathname === '/login';
      }
    });
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.lang = this.funcs.getLang();
  }

  logOut() {
    this.funcs.logOutUser();
  }

  showNav() {
    this.navClass = (!this.navClass) ? "active" : "";
  }
  closeNavIfOpen(): void {
    this.navClass = "";
  }

  changeLang() {
    if (this.lang.languageCode == "en") {
      this.lang = <Language>{
        languageCode: "fr",
        languageText: "Français"
      }
    } else {
      this.lang = <Language>{
        languageCode: "en",
        languageText: "English"
      }
    }

    this.funcs.setLang(this.lang.languageCode);
    location.reload();
  }

  nameFromEmail(email: string): string {
    if (!email) {
      return "";
    }
    if (email.indexOf("@") == -1) {
      return email;
    }
    return email.slice(0, email.indexOf("@"));
  }
}
