import { Component } from '@angular/core';
// import {Title} from "@angular/platform-browser";

import { Dealer } from '../../data/dealer';
import { DealerService } from '../../services/dealer.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.css']
})
export class DealersComponent {
  dealers: Dealer[] = [];
  displayedDealers = this.dealers;
  search = "";

  constructor(
    // private titleService: Title,
    private dealerService: DealerService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.dealerService.getDealers().subscribe(dealers => {
      // this.titleService.setTitle("Dealers - Helm Service Consulting");
      dealers.forEach(d => {
        d.dealerName = this.funcs.capitalize(d.dealerName);
      });
      this.dealers = dealers;
      // Angular has a bug where the model is not updated on browser back button.
      // This ugly hack should fix it.
      var searchBox = <HTMLInputElement>document.querySelector("#dealerFilter") || <HTMLInputElement>{};
      this.search = searchBox.value;
      this.displayDealers();
    });
  }
  displayDealers() {
    this.displayedDealers = this.filterDealers();
    this.funcs.hideSpinner();
  }

  private filterDealers = () => {
    return this.dealers.filter(this.isDealer);
  };

  private isDealer = (d: Dealer) => {
    var ciSearch = this.search.toLowerCase();
    var ciName = d.dealerName.toLowerCase();
    var ciCode = d.dealerCode.toLowerCase();
    var filtered = (ciCode.indexOf(ciSearch) > -1 || ciName.indexOf(ciSearch) > -1) || !ciSearch;
    return filtered
  };
}
