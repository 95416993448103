import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AnswerType } from '../data/answer-type';

@Injectable({
  providedIn: 'root',
})
export class AnswerTypeService {
  private answerTypeUrl = `${environment.apiUrl}/api/AnswerType`;

  constructor(private http: HttpClient) { }

  getAnswerType(AnswerTypeID: string): Observable<AnswerType> {
    const url = `${this.answerTypeUrl}/${AnswerTypeID}`;
    return this.http.get<AnswerType>(url)
      .pipe(
        tap(_ => this.log('fetched answer type')),
        catchError(this.handleError<AnswerType>('getAnswerType'))
      );
  }

  getAnswerTypes(): Observable<AnswerType[]> {
    const url = `${this.answerTypeUrl}`;
    return this.http.get<AnswerType[]>(url)
      .pipe(
        tap(_ => this.log('fetched answer types')),
        catchError(this.handleError<AnswerType[]>('getAnswerTypes'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}