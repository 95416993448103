import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './controllers/home/home.component';
import { LoginComponent } from './controllers/login/login.component';
import { AdminComponent } from './controllers/admin/admin.component';
import { UserComponent } from './controllers/user/user.component';
import { UsersComponent } from './controllers/users/users.component';
import { DealersComponent } from './controllers/dealers/dealers.component';
import { DealerComponent } from './controllers/dealer/dealer.component';
import { InspectionComponent } from './controllers/inspection/inspection.component';
import { ObservationComponent } from './controllers/observation/observation.component';
import { ReportComponent } from './controllers/report/report.component';
import { QuestionComponent } from './controllers/question/question.component';
import { QuestionsComponent } from './controllers/questions/questions.component';
import { FormComponent } from './controllers/form/form.component';
import { FormsComponent } from './controllers/forms/forms.component';
import { FormSectionsComponent } from './controllers/form-sections/form-sections.component';
import { FormSectionComponent } from './controllers/form-section/form-section.component';
import { FormQuestionComponent } from './controllers/form-question/form-question.component';
import { FormQuestionsComponent } from './controllers/form-questions/form-questions.component';
import { InspectionTypesComponent } from './controllers/inspection-types/inspection-types.component';
import { InspectionTypeComponent } from './controllers/inspection-type/inspection-type.component';
import { InspectionFormsComponent } from './controllers/inspection-forms/inspection-forms.component';
import { InspectionFormComponent } from './controllers/inspection-form/inspection-form.component';
import { ExportComponent } from './controllers/export/export.component';
import { DownloadComponent } from './controllers/download/download.component';
import { InspectionsComponent } from './controllers/inspections/inspections.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'home/:UserID', component: HomeComponent, title: "Home - Helm Service Consulting"},
  {path: 'home', component: HomeComponent, title: "Consultant Home - Helm Service Consulting"},
  {path: 'login', component: LoginComponent, title: "Log In - Helm Service Consulting"},
  {path: 'admin', component: AdminComponent, title: "Admin - Helm Service Consulting"},
  {path: 'users', component: UsersComponent, title: "Users - Helm Service Consulting"},
  {path: 'user/:userID', component: UserComponent, title: "User - Helm Service Consulting"},
  {path: 'dealers', component: DealersComponent, title: "Dealers - Helm Service Consulting"},
  {path: 'dealer/:dealerID', component: DealerComponent, title: "Helm Service Consulting"},
  {path: 'questions', component: QuestionsComponent, title: "Questions - Helm Service Consulting"},
  {path: 'question/:questionID', component: QuestionComponent, title: "Helm Service Consulting"},
  {path: 'form-questions', component: FormQuestionsComponent, title: "Form Questions - Helm Service Consulting"},
  {path: 'form-question/:formQuestionID', component: FormQuestionComponent, title: "Helm Service Consulting"},
  {path: 'forms', component: FormsComponent, title: "Forms - Helm Service Consulting"},
  {path: 'form/:formID', component: FormComponent, title: "Helm Service Consulting"},
  {path: 'form-sections', component: FormSectionsComponent, title: "Form Sections - Helm Service Consulting"},
  {path: 'form-section/:formSectionID', component: FormSectionComponent, title: "Helm Service Consulting"},
  {path: 'inspection-types', component: InspectionTypesComponent, title: "Inspection Types - Helm Service Consulting"},
  {path: 'inspection-type/:inspectionTypeID', component:InspectionTypeComponent, title: "Helm Service Consulting"},
  {path: 'inspection-forms', component: InspectionFormsComponent, title: "Inspection Forms - Helm Service Consulting"},
  {path: 'inspection-form/:inspectionFormID', component:InspectionFormComponent, title: "Helm Service Consulting"},
  {path: 'inspection', component: InspectionComponent, title: "Helm Service Consulting"},
  {path: 'inspection/:inspectionID', component: InspectionComponent, title: "Helm Service Consulting"},
  {path: 'observation/:inspectionID/:formID', component: ObservationComponent, title: "Helm Service Consulting"},
  {path: 'report/:inspectionID', component: ReportComponent, title: "Report - Helm Service Consulting"},
  {path: 'export', component: ExportComponent, title: "Helm Service Consulting - Export Data"},
  {path: 'download/:inspectionID', component: DownloadComponent, title: "Helm Service Consulting - Download Data"},
  {path: 'inspections', component: InspectionsComponent, title: "Helm Service Consulting - All Inspections"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
