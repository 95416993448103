export interface User {
    userID: string;
    roleID: number;
    roleName: string;
    consultantID: string;
    firstName: string;
    lastName: string;
    terminationDate: Date|null;
}

export const Users: User[] = [];
export const UserCurrent: User = {
    userID: "",
    roleID: 0,
    roleName: "",
    consultantID: "",
    firstName: "",
    lastName: "",
    terminationDate: null
};
