import { Component } from '@angular/core';
import { Inspection, InspectionFull } from '../../data/inspection';
import { InspectionService } from '../../services/inspection.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrls: ['./inspections.component.css']
})
export class InspectionsComponent {
  inspections: InspectionFull[] = [];
  displayedInspections = this.inspections;
  search = "";
  inspectionType: string = "I";

  constructor(
    private inspectionService: InspectionService,
    public funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.inspectionService.getInspectionsFull(0, "", this.inspectionType).subscribe(inspections => {
      // this.titleService.setTitle("Dealers - Helm Service Consulting");
      // inspections.forEach(i => {
      //   i.dealerName = this.funcs.capitalize(i.dealerName);
      // });
      this.inspections = inspections;
      // Angular has a bug where the model is not updated on browser back button.
      // This ugly hack should fix it.
      var searchBox = <HTMLInputElement>document.querySelector("#dealerFilter") || <HTMLInputElement>{};
      this.search = searchBox.value;
      this.displayInspections();
    });
  }
  private filterInspections = () => {
    return this.inspections;//.filter(this.isDealer);
  };
  displayInspections() {
    this.displayedInspections = this.filterInspections();
    this.funcs.hideSpinner();
  }
  changeInspectionType(e: Event) {
    var target = <HTMLInputElement>e.target;
    this.inspectionType = target.value || "";
    this.inspectionService.getInspectionsFull(0, "", this.inspectionType).subscribe(inspections => {
      this.inspections = inspections;
      // var searchBox = <HTMLInputElement>document.querySelector("#dealerFilter") || <HTMLInputElement>{};
      // this.search = searchBox.value;
      this.displayInspections();
    });
  }
}
