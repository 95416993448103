import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

import { InspectionFull, InspectionFormFull } from '../../data/inspection';
import { AnswerError, AnswerFull } from '../../data/answer';
import { InspectionService } from '../../services/inspection.service';
import { UserService } from '../../services/user.service';
import { AnswerService } from '../../services/answer.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.css']
})
export class InspectionComponent {
  inspectionID: number = 0;
  inspectionIDstring: string = "";
  inspection: InspectionFull = <InspectionFull>{};
  dealerName: string = "";

  inspectionType: number = 0;
  inspectionForms: InspectionFormFull[] = [];
  requiredData = true;
  answerErrors: AnswerError[] = [];
  daysSinceCompletion: number = 0;

  constructor(
    private titleService: Title,
    public funcs: FuncsService,
    private route: ActivatedRoute,
    private router: Router,
    private inspectionService: InspectionService,
    private answerService: AnswerService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.funcs.showSpinner();
    this.funcs.checkConsultant();
    this.inspectionIDstring = this.route.snapshot.paramMap.get('inspectionID') || "";

    if (this.inspectionIDstring) {
      this.inspectionID = parseInt(this.inspectionIDstring, 10);
      this.getInspection();
    } else {
      this.getLatestInspection();
    }
  }

  checkRequired(): void {
    this.answerErrors.length = 0;
    this.requiredData = true;
    var tabIdx: number = 0;
    var requiredExclude = ["Yes/No", "Check Box", "Calculated"];

    if (!this.inspection.completedDate) {
      this.answerService.getAnswersFull(this.inspectionID, "en").subscribe(a => {
        var answersDeDuped: AnswerFull[] = this.funcs.deDupAnswers(a);
        this.inspectionForms.forEach(f => {
          if (!f.optional && !a.some(aa => aa.formCode == f.formCode)) {
            this.requiredData = false;
            this.answerErrors.push({
              "errorText": `No data for `,
              "formName": f.formName,
              "formID": f.formID,
              "tabIdx": 0
            });
          }
        });
        var answersDeDuped: AnswerFull[] = this.funcs.clearBlankTabs(answersDeDuped);
        answersDeDuped.filter(q => q.required && requiredExclude.indexOf(q.answerTypeName) == -1).forEach(q => {
          // Make sure that a number doesn't fail if 0
          tabIdx = 0;
          if (q.maxInstances > 1) {
            tabIdx = q.tabIndex;
          }
          if (!q.answerText) {
            this.requiredData = false;
            this.answerErrors.push({
              "errorText": `Missing required field in form '${q.questionText}'`,
              "formName": q.formName,
              "formID": q.formID,
              "tabIdx": tabIdx
            });
          }
        });
      });
    }
  }

  getLatestInspection(): void {
    this.inspectionService.getLatestInspection(this.userService.getCurrentUser().userID).subscribe(ins => {
      this.inspectionID = ins.inspectionID;
      window.history.pushState("{ additionalInformation: 'Updated the URL with JS' }", `Dealer - Helm Service Consulting`, `/inspection/${this.inspectionID}`); 
      this.dealerName = this.funcs.capitalize(ins.dealerName);
      this.inspection = ins;
      this.getInspectionForms();
      this.checkRequired();
    });
  }

  getInspection(): void {
    this.inspectionService.getInspectionFull(this.inspectionID).subscribe(ins => {
      this.dealerName = this.funcs.capitalize(ins.dealerName);
      this.inspection = ins;
      this.getInspectionForms();
      this.checkRequired();
    });
  }

  getInspectionForms(): void {
    var today = new Date();
    var completedDate: Date = today;
    if (this.inspection.completedDate) {
      completedDate = new Date(this.inspection.completedDate);
      this.daysSinceCompletion = (+today - +completedDate) / 1000 / 24 / 60 / 60;
    }
    
    this.titleService.setTitle(`${this.funcs.capitalize(this.inspection.dealerName)} - Helm Service Consulting`);
    this.inspectionService.getInspectionFormsFull(this.inspectionID).subscribe(forms => {
      this.inspectionForms = forms;
      this.funcs.hideSpinner();
    });
  }

  complete(): void {
    this.funcs.showSpinner();
    // Is this where we should check for completion?
    this.inspectionService.completeInspection(this.inspectionID, this.userService.getCurrentUser()).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }

  delete(): void {
    if (window.confirm("Delete entire inspection?")) {
      this.funcs.showSpinner();
      // if is user or admin
      this.inspectionService.deleteInspection(this.inspectionID).subscribe(rsp => {
        this.funcs.hideSpinner();
        // location.reload();
        this.router.navigateByUrl('/home');
      });
    }
  }
}
