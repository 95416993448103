<h1>Home</h1>

<section>
    <h2>{{selectedUser}}</h2>
    <p><a href="/dealers">Find a Dealer</a> (To start new visit.)</p>
    <p><a href="/user/{{selectedUser}}">Change Your Password</a></p>
</section>

<section>
    <h3>In-Progress <span>({{inProgressCount}})</span></h3>
    <p *ngFor="let ip of inProgress">
        <a href="/inspection/{{ip.inspectionID}}">{{funcs.capitalize(ip.dealerName)}} (<code>{{ip.dealerCode}}</code>) {{ip.inspectionTypeName}}</a>
            - Started {{funcs.dateFormat(ip.createdDate)}}
    </p>
</section>

<section>
    <h3>Completed <span>({{completedCount}})</span></h3>
    <p *ngFor="let c of completed">
        <a href="/inspection/{{c.inspectionID}}">{{funcs.capitalize(c.dealerName)}} (<code>{{c.dealerCode}}</code>) {{c.inspectionTypeName}}</a>
            - Completed {{funcs.dateFormat(c.completedDate)}}
    </p>
</section>
