import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Inspection, InspectionFull, InspectionFormFull } from '../data/inspection';
import { User } from '../data/user';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  private apiEndpoint = `${environment.apiUrl}/api/Inspection`;

  constructor(private http: HttpClient) { }

  addInspection(inspection: Inspection): Observable<number> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<number>(url, inspection)
      .pipe(
        tap(_ => this.log('added inspection')),
        catchError(this.handleError<number>('addInspection'))
      );
  }

  completeInspection(InspectionID: number, user: User): Observable<number> {
    const url = `${this.apiEndpoint}/${InspectionID}`;
    return this.http.post<number>(url, user)
      .pipe(
        tap(_ => this.log('Completed inspection')),
        catchError(this.handleError<number>('completeInspection'))
      );
  }

  getInspection(InspectionID: number): Observable<Inspection> {
    const url = `${this.apiEndpoint}/${InspectionID}`;
    return this.http.get<Inspection>(url)
      .pipe(
        tap(_ => this.log('fetched inspection')),
        catchError(this.handleError<Inspection>('getInspection'))
      );
  }

  getLatestInspection(UserID: string): Observable<InspectionFull> {
    const url = `${this.apiEndpoint}/Latest/${UserID}`;
    return this.http.get<InspectionFull>(url)
      .pipe(
        tap(_ => this.log('fetched latest inspection')),
        catchError(this.handleError<InspectionFull>('getLatestInspection'))
      );
  }

  getInspections(DealerID: number, UserID: string, State: string): Observable<Inspection[]> {
    const url = `${this.apiEndpoint}?dealerId=${DealerID}&userId=${UserID}&state=${State}`;
    return this.http.get<Inspection[]>(url)
      .pipe(
        tap(_ => this.log('fetched inspections')),
        catchError(this.handleError<Inspection[]>('getInspections'))
      );
  }

  getInspectionsFull(DealerID: number, UserID: string, State: string): Observable<InspectionFull[]> {
    const url = `${this.apiEndpoint}/Full?dealerId=${DealerID}&userId=${UserID}&state=${State}`;
    return this.http.get<InspectionFull[]>(url)
      .pipe(
        tap(_ => this.log('fetched inspections')),
        catchError(this.handleError<InspectionFull[]>('getInspections'))
      );
  }

  getInspectionFull(InspectionID: number): Observable<InspectionFull> {
    const url = `${this.apiEndpoint}/Full/${InspectionID}`;
    return this.http.get<InspectionFull>(url)
      .pipe(
        tap(_ => this.log('fetched inspection full')),
        catchError(this.handleError<InspectionFull>('getInspectionFull'))
      );
  }

  getInspectionFormsFull(InspectionID: number): Observable<InspectionFormFull[]> {
    const url = `${this.apiEndpoint}Form/Full/${InspectionID}`;
    return this.http.get<InspectionFormFull[]>(url)
      .pipe(
        tap(_ => this.log('fetched inspection forms full')),
        catchError(this.handleError<InspectionFormFull[]>('getInspectionFormsFull'))
      );
  }

  deleteInspection(InspectionID: number): Observable<number> {
    const url = `${this.apiEndpoint}/${InspectionID}`;
    return this.http.delete<number>(url)
      .pipe(
        tap(_ => this.log('terminated user')),
        catchError(this.handleError<number>('terminateUser'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}