import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User, Users } from '../data/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userUrl = `${environment.apiUrl}/api/User`;

  constructor(private http: HttpClient) { }

  getCurrentUser(): User {
    var user = <User>{};
    var d = window.localStorage.getItem("user");
    if (!d) {
      return user;
    }
    var dJson = JSON.parse(d);

    user.userID = dJson.userID;
    user.roleID = dJson.roleID;
    user.roleName = dJson.roleName;
    return user;
  }

  getUser(UserID: string): Observable<User> {
    const url = `${this.userUrl}/${UserID}`;

    return this.http.get<User>(url)
      .pipe(
        tap(_ => this.log('fetched user')),
        catchError(this.handleError<User>('getUser'))
      );
  }

  getUsers(): Observable<User[]> {
    const url = `${this.userUrl}`;
    return this.http.get<User[]>(url)
      .pipe(
        tap(_ => this.log('fetched users')),
        catchError(this.handleError<User[]>('getUsers'))
      );
  }

  getUserAuth(UserID: string, password: string): Observable<User> {
    const url = `${this.userUrl}/${UserID}`;
    return this.http.post<User>(url, {password: password})
      .pipe(
        tap(_ => this.log('fetched user auth')),
        catchError(this.handleError<User>('getUserAuth'))
      );
  }

  createUser(firstName: string, lastName: string, email: string, role: number, password: string): Observable<User> {
    const url = `${this.userUrl}`;
    return this.http.post<User>(url, {
      FirstName: firstName, LastName: lastName, UserID: email, RoleID: role, passwordHash: password
    }).pipe(
        tap(_ => this.log('created user')),
        catchError(this.handleError<User>('createUser'))
    );
  }

  updateUser(firstName: string, lastName: string, email: string, role: number): Observable<User> {
    const url = `${this.userUrl}/${email}`;
    return this.http.put<User>(url, {UserID: email, FirstName: firstName, LastName: lastName, RoleID: role})
      .pipe(
        tap(_ => this.log('updated user')),
        catchError(this.handleError<User>('createUser'))
      );
  }

  terminateUser(email: string): Observable<number> {
    const url = `${this.userUrl}/${email}`;
    return this.http.delete<number>(url)
      .pipe(
        tap(_ => this.log('terminated user')),
        catchError(this.handleError<number>('terminateUser'))
      );
  }

  updatePassword(email: string, password: string): Observable<User> {
    const url = `${this.userUrl}/Password/${email}`;
    return this.http.post<User>(url, {passwordHash: password, password: password})
      .pipe(
        tap(_ => this.log('updated password')),
        catchError(this.handleError<User>('createUser'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}