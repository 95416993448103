import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Form } from '../../data/form';
import { FormService } from '../../services/form.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  form: Form = <Form>{};
  formID: string = "";
  displayTypes: string[] = [
    "header",
    "cover",
    "no-header"
  ];

  constructor(
    // private titleService: Title,
    private formService: FormService,
    // private answerTypeService: AnswerTypeService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.formID = this.route.snapshot.paramMap.get('formID') || "";
    this.funcs.showSpinner();
    this.formService.getForm(this.formID).subscribe(form => {
      this.form = form;
      this.funcs.hideSpinner();
    });
  }

  updateForm(): void {
    this.funcs.showSpinner();
    this.formService.updateForm(this.formID, this.form).subscribe(rsp => {
      this.funcs.hideSpinner();
    });
  }
}
