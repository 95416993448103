import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InspectionForm } from '../../data/inspection-form';
import { InspectionFormService } from '../../services/inspection-form.service';
import { InspectionType } from '../../data/inspection-type';
import { InspectionTypeService } from '../../services/inspection-type.service';
import { Form } from '../../data/form';
import { FormService } from '../../services/form.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-inspection-form',
  templateUrl: './inspection-form.component.html',
  styleUrls: ['./inspection-form.component.css']
})
export class InspectionFormComponent {
  inspectionForm: InspectionForm = <InspectionForm>{};
  inspectionForms: InspectionForm[] = [];
  inspectionTypes: InspectionType[] = [];
  forms: Form[] = [];
  countries: string[] = [
    "",
    "US",
    "Canada"
  ];
  inspectionFormID: string = "";

  constructor(
    // private titleService: Title,
    private inspectionFormService: InspectionFormService,
    private inspectionTypeService: InspectionTypeService,
    private formService: FormService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.inspectionFormID = this.route.snapshot.paramMap.get('inspectionFormID') || "";
    this.funcs.showSpinner();
    this.inspectionFormService.getInspectionForm(this.inspectionFormID).subscribe(inspectionForm => {
      this.inspectionForm = inspectionForm;
      this.inspectionTypeService.getInspectionTypes().subscribe(inspectionTypes => {
        this.inspectionTypes = inspectionTypes;

        this.formService.getForms().subscribe(forms => {
          this.forms = forms;
          this.inspectionForm.inspectionTypeID = this.inspectionTypes[0].inspectionTypeID;
          // this.inspectionForm.formID = this.forms[0].formID;
          // this.inspectionForm.country = "US";
          // this.inspectionForm.maxInstances = 1;
          // this.inspectionForm.formOrder = 1;
          this.funcs.hideSpinner();
        });
        // this.funcs.hideSpinner();
      });
      // this.funcs.hideSpinner();
    });
  }

  updateInspectionForm(): void {
    this.funcs.showSpinner();
    this.inspectionFormService.updateInspectionForm(this.inspectionFormID, this.inspectionForm).subscribe(rsp => {
      this.funcs.hideSpinner();
    });
  }
}
