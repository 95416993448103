import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserCurrent } from '../../data/user';
import { UserService } from '../../services/user.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  username = "";
  password = "";
  message = "";

  constructor(
    private userService: UserService,
    private funcs: FuncsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.funcs.currentUser(UserCurrent);

    if (UserCurrent.roleName === "ServiceConsultant") {
      this.router.navigateByUrl(`/home/${UserCurrent.userID}`);
    }
    if (UserCurrent.roleName === "ServiceConsultantAdmin") {
      this.router.navigateByUrl('/admin');
    }
  }

  checkPw(): void {
    this.message = "";
    this.funcs.showSpinner();
    this.userService.getUserAuth(this.username, this.password).subscribe(user => {
      this.funcs.hideSpinner();
      if (user && user.userID) {
        this.funcs.setUser(user);
        if (UserCurrent.roleName === "ServiceConsultant") {
          this.router.navigateByUrl(`/home/${user.userID}`);
        }
        if (UserCurrent.roleName === "ServiceConsultantAdmin") {
          this.router.navigateByUrl('/admin');
        }
      } else {
        this.message = "Incorrect username or password.";
      }
    });
  }
}
