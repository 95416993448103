<main [className]="prod ? '' : 'uat'">
  <header>
    <div>
      <p><a href="/home">{{title}}</a> <span *ngIf="!prod"> (UAT)</span></p>
      <p><a [routerLink]="" (click)="showNav()">{{nameFromEmail(currentUser.userID)}}</a></p>
    </div>
    <nav class="{{navClass}}">
      <ul>
        <li><a href="/user/{{currentUser.userID}}">Account</a></li>
        <li><a [routerLink]="" (click)="changeLang()">{{lang.languageText}}</a></li>
        <li><a [routerLink]="" *ngIf="!hideLogout" (click)="logOut()">Log Out</a></li>
      </ul>
    </nav>
  </header>
  
  <article (click)="closeNavIfOpen()">
  <router-outlet></router-outlet>
  </article>
</main>
