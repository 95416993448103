<h1>Users</h1>

<section *ngIf="isAdmin">
<p><label>First Name: </label> <input type="text" name="decoy1" [(ngModel)]="firstName" autocomplete="off" aria-autocomplete="none"><input type="text" name="hack-to-stop-edge-autofill" style="display:none"></p>
<p><label>Last Name: </label> <input type="text" name="decoy2" [(ngModel)]="lastName" autocomplete="off" aria-autocomplete="none"><input type="text" name="hack-to-stop-edge-autofill" style="display:none"></p>
<p><label>Email: </label> <em>{{email}}</em></p>
<p><label>Role </label>
    <select [(ngModel)]="role">
        <option value="13">Consultant</option>
        <option value="14">Admin</option>
    </select>
</p>
<p *ngIf="user.terminationDate">Terminated on: {{user.terminationDate}}</p>
<!-- <p><label>Password </label> <input type="password" [(ngModel)]="password" autocomplete="new-password"></p> -->
<p><button (click)="updateUser()">Update User</button></p>
</section>

<section>
    <p><label>New Password </label> <input type="password" [(ngModel)]="password" autocomplete="new-password" (keyup.enter)="changePassword()"></p>
    <p><label>Confirm Password </label> <input type="password" [(ngModel)]="passwordConfirm" autocomplete="new-password" (keyup.enter)="changePassword()"></p>
<!-- <p><label>Password </label> <input type="password" [(ngModel)]="password" autocomplete="new-password"></p> -->
    <p class="warning">{{message}}</p>
<p><button (click)="changePassword()">Change Password</button></p>
</section>

<section *ngIf="isAdmin && !user.terminationDate">
    <h2>Terminate User</h2>
    <p>To revoke the credentials of this user: press the button. (You will need to contact IT to undo this.)
    <p><button (click)="terminateUser()">Remove Access</button></p>
</section>