import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InspectionType } from '../../data/inspection-type';
import { InspectionTypeService } from '../../services/inspection-type.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-inspection-type',
  templateUrl: './inspection-type.component.html',
  styleUrls: ['./inspection-type.component.css']
})
export class InspectionTypeComponent {
  inspectionType: InspectionType = <InspectionType>{};
  inspectionTypeID: string = "";

  constructor(
    // private titleService: Title,
    private inspectionTypeService: InspectionTypeService,
    private route: ActivatedRoute,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.inspectionTypeID = this.route.snapshot.paramMap.get('inspectionTypeID') || "";
    this.funcs.showSpinner();
    this.inspectionTypeService.getInspectionType(this.inspectionTypeID).subscribe(inspectionType => {
      this.inspectionType = inspectionType;
      this.funcs.hideSpinner();
    });
  }

  updateForm(): void {
    this.funcs.showSpinner();
    this.inspectionTypeService.updateInspectionType(this.inspectionTypeID, this.inspectionType).subscribe(rsp => {
      this.funcs.hideSpinner();
    });
  }
}
