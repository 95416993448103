<h1>Form Questions</h1>

<section>
    <p><label>Form Section </label>
        <select [(ngModel)]="formQuestion.formSectionID">
            <option value="" selected="selected">Select Form Section</option>
            <option *ngFor="let s of sections" value="{{s.formSectionID}}">{{s.title}}</option>
        </select>
    </p>
    <p><label>Question </label>
        <select [(ngModel)]="formQuestion.questionID">
            <option value="" selected="selected">Select Question</option>
            <option *ngFor="let q of questions" value="{{q.questionID}}">{{q.questionText}}</option>
        </select>
    </p>
    <p><label><input type="checkbox" [(ngModel)]="formQuestion.required" value="1"> Required</label></p>
    <p><button (click)="addFormQuestion()">New Form Question</button></p>
</section>

<section>
    <!-- <p>
        Filter by Dealer Code: <input id="dealerFilter" [(ngModel)]="search" (keyup)="displayDealers()" autofocus="autofocus"> 
    </p> -->
    <div *ngFor="let q of formQuestions">
        <p><a href="/form-question/{{q.formQuestionID}}">{{q.formName}} - {{q.sectionTitle}} - {{q.questionText}}</a></p>
    </div>
</section>
