<h1>Dealer</h1>

<section>
    <h2>{{dealerName}} - <code>{{dealerCode}}</code></h2>
    <p>
        <a href="mailto:{{dealer.email}}">{{dealer.email}}</a>
    </p>
    <p>
        {{funcs.capitalize(dealer.address1)}}
        {{funcs.capitalize(dealer.address2)}}<br>
        {{funcs.capitalize(dealer.city)}}, {{dealer.state}} &nbsp;{{dealer.zipCode}}<br>
        <a href="tel:{{funcs.stripNonNumeric(dealer.phone)}}">{{dealer.phone}}</a>
    </p>

    <h3>Visit Type</h3>
    <p *ngFor="let inspectionType of inspectionTypes; let first = first">
        <label>
            <input type="radio" name="inspection-type"
                value="{{inspectionType.inspectionTypeID}}"
                [checked]="inspectionType.inspectionTypeDefault"
                (change)="changeInspectionType($event)">
            {{inspectionType.inspectionTypeName}}
        </label>
        <!-- <select [(ngModel)]="inspectionType">
            <option *ngFor="let inspectionType of inspectionTypes" [value]="inspectionType.inspectionTypeID" [selected]="inspectionType.inspectionTypeCode == 'mel-followup'">
                {{inspectionType.inspectionTypeName}}
            </option>
        </select> -->
    </p>
    <p><button (click)="addNewInspection()">Start New Visit</button></p>
</section>

<section>
    <h3>In-Progress <span>({{inProgressCount}})</span></h3>
    <p *ngFor="let ip of inProgress">
        <a href="/inspection/{{ip.inspectionID}}">{{ip.inspectionTypeName}}</a>
            - Started {{funcs.dateFormat(ip.createdDate)}}
    </p>
</section>

<section>
    <h3>Completed <span>({{completedCount}})</span></h3>
    <!-- <p *ngFor="let c of completed"><a href="/inspection/{{c.inspectionID}}">{{getInspectionTypeName(c.inspectionTypeID)}} Completed {{c.completedDate}}</a></p> -->
    <p *ngFor="let c of completed">
        <a href="/inspection/{{c.inspectionID}}">{{c.inspectionTypeName}}</a>
            - Completed {{funcs.dateFormat(c.completedDate)}}
    </p>
</section>
