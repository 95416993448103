<h1>Users</h1>

<section>
    <p><label>First Name </label> <input type="text" name="user-first-name" [(ngModel)]="firstName"></p>
    <p><label>Last Name </label> <input type="text" name="user-last-name" [(ngModel)]="lastName"></p>
    <p><label>Email </label> <input type="text" name="user-email" [(ngModel)]="email" autocomplete="new-username"></p>
    <p><label>Role </label>
        <select [(ngModel)]="role">
            <option value="13">Consultant</option>
            <option value="14">Admin</option>
        </select>
    </p>
    <p><label>Password </label> <input type="password" [(ngModel)]="password" autocomplete="new-password"></p>
    <p><button (click)="createNewUser()">Create New User</button></p>
</section>

<section>
<div *ngFor="let user of users">
    <p><a href="/user/{{user.userID}}">{{user.userID}}</a> 
        <span *ngIf="user.roleName == 'ServiceConsultantAdmin'"> (Admin) </span> 
        <span *ngIf="user.roleName == 'ServiceConsultant'"> (Consultant) </span> 
        <span *ngIf="isTerminated(user.terminationDate)"> (Terminated)</span>
        <span *ngIf="!isTerminated(user.terminationDate)"><a href="/home/{{user.userID}}">Impersonate &rarr;</a></span>
    </p>
</div>
</section>
