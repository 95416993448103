<h1>Forms</h1>

<section>
    <p><label>Inspection Type Code </label> <input type="text" name="inspection-type-code" [(ngModel)]="inspectionType.inspectionTypeCode" required></p>
    <p><label>Inspection Type Name </label> <input type="text" name="inspection-type-name" [(ngModel)]="inspectionType.inspectionTypeName" required></p>
    <p><label>Inspection Type Description </label> <input type="text" name="inspection-type-desc" [(ngModel)]="inspectionType.inspectionTypeDesc"></p>
    <p><label> 
            <input type="checkbox" name="inspection-type-default" [(ngModel)]="inspectionType.inspectionTypeDefault" value="1">
            Default
        </label> (Only one inspection type should be set as default.) 
    </p>
    <p><button (click)="addForm()">New Inspection Type</button></p>
</section>

<section>
    <div *ngFor="let it of inspectionTypes">
        <p><a href="/inspection-type/{{it.inspectionTypeID}}">{{it.inspectionTypeName}}</a></p>
    </div>
</section>
