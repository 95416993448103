<h1>Export Data</h1>

<section>
    <p>For Inspections: 
        <label>
            <input id="completedInspection" type="radio" name="inspectionType" value="C" checked="checked"> Completed
        </label>
        <label>
            <input id="incompleteInspection" type="radio" name="inspectionType" value="I"> Incomplete
        </label>
        <label>
            <input id="anyInspection" type="radio" name="inspectionType" value="A"> All
        </label>
    </p>
    <div *ngFor="let i of inspections">
        <p><code>{{i.dealerCode}}</code> {{funcs.dateFormat(i.createdDate)}} {{i.createdUserID}} <a href="download/{{i.inspectionID}}">Download</a></p>
    </div>
</section>
