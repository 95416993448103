import { Component } from '@angular/core';

import { FormQuestion, FormQuestionFull } from '../../data/form-question';
import { Question } from '../../data/question';
import { FormSection } from '../../data/form-section';
import { FormQuestionService } from '../../services/form-question.service';
import { QuestionService } from '../../services/question.service';
import { FormSectionService } from '../../services/form-section.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-form-questions',
  templateUrl: './form-questions.component.html',
  styleUrls: ['./form-questions.component.css']
})
export class FormQuestionsComponent {
  formQuestion: FormQuestion = <FormQuestion>{};
  formQuestions: FormQuestionFull[] = [];
  questions: Question[] = [];
  sections: FormSection[] = [];

  constructor(
    // private titleService: Title,
    private formQuestionService: FormQuestionService,
    private questionService: QuestionService,
    private formSectionService: FormSectionService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.formQuestionService.getFullFormQuestions(0).subscribe(questions => {
      this.formQuestions = questions;
      this.funcs.hideSpinner();
    });
    this.questionService.getQuestions().subscribe(questions => {
      this.questions = questions;
    });
    this.formSectionService.getFormSections().subscribe(sections => {
      this.sections = sections;
    });
  }

  addFormQuestion(): void {
    this.funcs.showSpinner();
    this.formQuestionService.addFormQuestion(this.formQuestion).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }
}
