import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Form } from '../data/form';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private apiEndpoint = `${environment.apiUrl}/api/Form`;

  constructor(private http: HttpClient) { }

  addForm(form: Form): Observable<Form> {
    const url = `${this.apiEndpoint}/`;
    return this.http.post<Form>(url, form)
      .pipe(
        tap(_ => this.log('Added Form')),
        catchError(this.handleError<Form>('addForm'))
      );
  }

  getForm(FormID: string): Observable<Form> {
    const url = `${this.apiEndpoint}/${FormID}`;
    return this.http.get<Form>(url)
      .pipe(
        tap(_ => this.log('fetched Form')),
        catchError(this.handleError<Form>('getForm'))
      );
  }

  getForms(): Observable<Form[]> {
    const url = `${this.apiEndpoint}`;
    return this.http.get<Form[]>(url)
      .pipe(
        tap(_ => this.log('fetched Forms')),
        catchError(this.handleError<Form[]>('getForms'))
      );
  }

  updateForm(formID: string, form: Form): Observable<Form> {
    const url = `${this.apiEndpoint}/${formID}`;
    return this.http.put<Form>(url, form)
      .pipe(
        tap(_ => this.log('Update form')),
        catchError(this.handleError<Form>('updateForm'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}