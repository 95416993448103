<h1>Form Sections</h1>

<section>
    <p><label>Form </label>
        <select [(ngModel)]="formSection.formID">
            <option *ngFor="let f of forms" value="{{f.formID}}">{{f.formName}}</option>
        </select>
    </p>
    <p><label>Title </label> <input type="text" name="form-section-title" [(ngModel)]="formSection.title"></p>
    <p><label>Description </label> <textarea name="form-section-desc" [(ngModel)]="formSection.desc"></textarea></p>
    <p><label>Report Layout </label>
        <select [(ngModel)]="formSection.reportLayout">
            <option *ngFor="let lo of layouts" value="{{lo}}">{{lo}}</option>
        </select>
    </p>
    <p><label>Order </label> <input type="number" step="1" name="form-section-order" [(ngModel)]="formSection.formSectionOrder" required></p>
    <p><button (click)="addFormSection()">New Form Section</button></p>
</section>

<section>
    <!-- <p>
        Filter by Dealer Code: <input id="dealerFilter" [(ngModel)]="search" (keyup)="displayDealers()" autofocus="autofocus"> 
    </p> -->
    <div *ngFor="let f of formSections">
        <p><a href="/form-section/{{f.formSectionID}}">{{f.title}}</a></p>
    </div>
</section>
