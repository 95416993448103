import { Component } from '@angular/core';

import { Form } from '../../data/form';
import { FormService } from '../../services/form.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent {
  form: Form = <Form>{};
  forms: Form[] = [];
  displayedForms = this.forms;
  search = "";
  displayTypes: string[] = [
    "header",
    "cover",
    "no-header"
  ];

  constructor(
    private formService: FormService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkConsultant();
    this.funcs.showSpinner();
    this.formService.getForms().subscribe(forms => {
      this.forms = forms;
      this.funcs.hideSpinner();
    });
    this.form.formDisplayed = this.displayTypes[0];
  }

  addForm(): void {
    this.funcs.showSpinner();
    this.formService.addForm(this.form).subscribe(rsp => {
      this.funcs.hideSpinner();
      location.reload();
    });
  }
}
