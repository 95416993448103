import { Component } from '@angular/core';
import { User, UserCurrent } from '../../data/user';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { FuncsService } from '../../services/funcs.service';

@Component({
  selector: 'app-user',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {
  users: User[] = [];
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  role: number = 13;
  password: string = "";
  now: number = new Date().getTime();
  user: User = UserCurrent;

  constructor(
    private router: Router,
    private userService: UserService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.currentUser(UserCurrent);
    this.funcs.showSpinner();

    if (UserCurrent.roleName !== "ServiceConsultantAdmin") {
      this.funcs.hideSpinner();
      this.router.navigateByUrl(`/user/${UserCurrent.userID}`);
    }

    this.userService.getUsers().subscribe(users => {
      this.funcs.hideSpinner();
      this.users = users;
    });
  }

  createNewUser(): void {
    this.userService.createUser(this.firstName, this.lastName, this.email, this.role, this.password).subscribe(rsp => {
      location.reload();
    });
  }

  timestamp(dateString: Date|null): number {
    if (!dateString) {
      return Infinity;
    }
    return +(new Date(dateString));
  }

  isTerminated(termDate: Date|null): boolean {
    var now = new Date();
    if (!termDate) {
      return false;
    }

    return (new Date(termDate).getTime() <= now.getTime() + now.getTimezoneOffset() * 60 * 1000);
  }
}
