<h1>Dealers</h1>

<section>
    <p>
        Filter by Dealer Code: <input id="dealerFilter" [(ngModel)]="search" (keyup)="displayDealers()" autofocus="autofocus"> 
    </p>
    <div *ngFor="let d of displayedDealers">
        <p><a href="/dealer/{{d.dealerID}}"><code>{{d.dealerCode}}</code> {{d.dealerName}}</a></p>
    </div>
</section>
