<h1>Inspections</h1>

<section>
    <!-- <p>
        Filter by Field Guy: <input id="userFilter" [(ngModel)]="search" (keyup)="displayInspections()" autofocus="autofocus"> 
    </p> -->
    <p>
        Inspection State: 
        <label>
            <input id="incompleteInspection" type="radio" name="inspectionState" value="I" checked="checked" (change)="changeInspectionType($event)"> Incomplete
        </label>
        <label>
            <input id="completedInspection" type="radio" name="inspectionState" value="C" (change)="changeInspectionType($event)"> Complete
        </label>
        <label>
            <input id="anyInspection" type="radio" name="inspectionState" value="A" (change)="changeInspectionType($event)"> All
        </label>
    </p>
    <ul *ngFor="let i of displayedInspections" id="inspectionList">
        <li>
            <a href="/inspection/{{i.inspectionID}}">#{{i.inspectionID}} ({{i.createdDate.slice(0, i.createdDate.indexOf("T"))}})</a> -
            <a href="/dealer/{{i.dealerID}}">{{funcs.capitalize(i.dealerName)}}</a> - 
            <a href="/home/{{i.createdUserID}}">{{i.createdUserID}}</a>
        </li>
    </ul>
</section>
